import _ from 'lodash';
import jwtDecode from 'jwt-decode';


export const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  try {
    const decoded: any = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  } catch (err) {
    return false;
  }
};


// checks if a jwt token was issued by our own server or by auth0
export const isIssuedByEnl = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  try {
    const decoded: any = jwtDecode(accessToken);

    return !_.isEmpty(decoded.iss);
  } catch (err) {
    return false;
  }
};


export const getTokenExpiresAt = (accessToken: string): number => {
  if (!accessToken) {
    return null;
  }

  try {
    const decoded: any = jwtDecode(accessToken);

    return decoded.exp;
  } catch (err) {
    return null;
  }
};

/**
 * @param token jwt encrypted 2-factor code
 * @returns number of seconds until token expires
 */
export const getExpiration = (token: string): number => {
  if (!token) {
    return -1;
  }

  try {
    const decoded: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    return decoded.exp - currentTime;
  } catch (err) {
    return -1;
  }
};
