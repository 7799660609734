/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useCallback,
} from 'react';
import _ from 'lodash';
import Promise from 'bluebird';
import type { FC, ReactNode } from 'react';

import { useRouter } from 'lib/hooks/useRouterIFrame';
import { useDispatch } from 'react-redux';
import debug from 'debug';
import { getTokenExpiresAt, isIssuedByEnl, isValidToken } from 'utils/jwt';
import { IdleProvider } from './useIdle';
import { useApi } from './useApi';
import LoadingSpinner from '../../components/Layout/LoadingSpinner/LoadingSpinner';
import { useErrors } from './useErrors';
import useIsIframe from './useIsIframe';


const log = debug('hooks:useAuth');


export interface User {
  id: string;
  avatar: string;
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  organizationId: string;
  [key: string]: any;
}


export interface Notary {
  id: string;
  [key: string]: any
}

interface AuthState {
  notaryId: any;
  isPendingAuthentication: boolean;
  isInitialised: boolean;
  isAuthenticated: boolean;
  tokenExpiresAt: number;

  secondFactorToken?: string | null;
  token?: string | null;
  refreshToken?: string | null;
  resources: string[];
  user?: User | null;
  notary?: Notary | null;
}

type RefreshAccessTokenFn = (() => Promise<any>) | ((_accessToken?: string, _user?: User, _refreshToken?: string) => Promise<any>);


interface AuthContextValue extends AuthState {
  method: 'JWT',
  getAuth: () => AuthState,
  login2fa: (email: string, password: string, recaptchaValue: string) => Promise<void>;
  login: (authCode: string) => Promise<void>;
  logout: () => void;
  initialise: (force?: boolean) => any;
  refreshAccessToken: RefreshAccessTokenFn
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isPendingAuthentication: boolean;
    isAuthenticated: boolean;
    user: User | null;
    refreshToken?: string;
    resources: string[];
    accessToken: string | null;
    token: string | null;
  };
};

type LoginAction = {
  type: 'LOGIN_2FA';
  payload: {
    token: string;
  };
};

type LoginTwoFactorAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    accessToken: string;
    refreshToken: string;
    tokenExpiresAt: number;
    resources: string[]
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action =
  | InitialiseAction
  | LoginTwoFactorAction
  | LoginAction
  | LogoutAction;

const initialAuthState: AuthState = {
  // if true, then pending second factor
  isPendingAuthentication: false,
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  notaryId: null,
  notary: null,
  tokenExpiresAt: null,
  token: null,
  refreshToken: null,
  resources: [],
  secondFactorToken: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, isPendingAuthentication, token, resources, accessToken, refreshToken } = action.payload;
      const notaryId = _.get(user, 'notary.id');

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        isPendingAuthentication,
        refreshToken,
        resources,
        token: accessToken,
        tokenExpiresAt: getTokenExpiresAt(accessToken),
        secondFactorToken: token,
        user,
        notary: _.get(user, 'notary'),
        notaryId,
      };
    }

    case 'LOGIN_2FA': {
      const { token } = action.payload;

      return {
        ...state,
        isPendingAuthentication: true,
        token: null,
        secondFactorToken: token,
      };
    }

    case 'LOGIN': {
      const { user, resources, accessToken, refreshToken, tokenExpiresAt } = action.payload;


      return {
        ...state,
        isPendingAuthentication: false,
        isAuthenticated: true,
        token: accessToken,
        refreshToken,
        tokenExpiresAt,
        secondFactorToken: null,
        resources,
        user,
      };
    }

    case 'LOGOUT': {
      return initialAuthState;
    }

    default: {
      return { ...state };
    }
  }
};


const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  getAuth: () => initialAuthState,
  login2fa: () => Promise.resolve(),
  login: () => Promise.resolve(),
  initialise: () => Promise.resolve(),
  logout: () => { },
  refreshAccessToken: () => Promise.resolve(),
});

export const publicRoutes = [
  '/account/login',
  '/account/reset-password/[token]',
  '/account/forgot-password',
  '/account/authenticate',
  '/account/verify-email',
];


export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const rdispatch = useDispatch();
  const router = useRouter();
  const errors = useErrors();
  const { api, setSession, set2FaSession } = useApi();

  const isIframe = useIsIframe();

  const login2fa = useCallback(async (email: string, password: string, recaptchaToken: string) => {
    try {
      const data = await api.login2fa(email, password, recaptchaToken);
      const { token } = data;

      set2FaSession(token);
      dispatch({
        type: 'LOGIN_2FA',
        payload: { token },
      });
    } catch (error) {
      errors.showError(error);
      console.log(error);
      throw error;
    }
  }, [api, errors, set2FaSession]);





  const login = useCallback(async (authCode: string) => {
    try {
      const token = localStorage.getItem('token');
      const data = await api.login(authCode, token);
      const { token: accessToken, refreshToken, user } = data;
      const tokenExpiresAt = getTokenExpiresAt(accessToken);

      setSession(accessToken, refreshToken);
      set2FaSession(null);
      const resources = await api.getUserResources();

      localStorage.removeItem('idleStart');

      dispatch({
        type: 'LOGIN',
        payload: {
          accessToken,
          refreshToken,
          tokenExpiresAt,
          user,
          resources,
        },
      });
    } catch (err) {
      errors.showError(err);
      console.error(err);
      throw err;
    }
  }, [api, errors, set2FaSession, setSession]);






  const logout = useCallback(() => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });

    window.location.href = '/account/login';
  }, [setSession]);





  const notifyParentWindow = useCallback((event) => {
    if (isIframe) {
      window.parent.postMessage({
        event,
      }, '*');
    }
  }, [isIframe]);


  const refreshAccessToken = useCallback(async (_accessToken?: string, _user?: User, _refreshToken?: string) => {
    try {
      log('refreshing access token');
      const rtoken = (!_refreshToken) ? localStorage.getItem('refreshToken') : _refreshToken;

      // refresh token is undefined if this app is loaded from within iframe
      if (isIssuedByEnl(_accessToken)) {
        log('access token is issued by auth0. skipping refresh of access token');

        return notifyParentWindow('refresh-access-token');
      }

      // if refresh token not defined or is invalid, logout

      // TODO: this need to be changed to behave differently if the issue access token is from auth0
      if ((!rtoken || !isValidToken(rtoken))) {
        if (isIframe) {
          return;
        }

        log('no refresh token. logging out');

        return logout();
      }

      const { token: accessToken, user, refreshToken } = (!_accessToken && !_user)
        ? await api.refreshAccessToken(rtoken)
        : { token: _accessToken, user: _user, refreshToken: rtoken };

      setSession(accessToken, refreshToken);

      dispatch({
        type: 'INITIALISE',
        payload: {
          refreshToken,
          accessToken,
          token: null,
          user,
          resources: state.resources,
          isPendingAuthentication: false,
          isAuthenticated: true,
        },
      });

      return notifyParentWindow('refresh-access-token');
    } catch (err) {
      errors.showError(err);
      console.error(err.response);
      throw err;
    }
  }, [api, errors, isIframe, logout, notifyParentWindow, setSession, state.resources]);




  const initialise = useCallback(async (forceUpdate?: boolean) => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const refreshToken = window.localStorage.getItem('refreshToken');
      const secondFactorCode = window.localStorage.getItem('token');

      // if accessToken in localStorage and it is valid + not expired
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, refreshToken);
        set2FaSession(null);

        if (!state.isInitialised || forceUpdate) {
          const [user, resources, roles, permissions, notaries] = await Promise.all([
            api.getUser(),
            api.getUserResources(),
            api.getRoles(),
            api.getPermissions(),
            api.getNotaries(),
          ]);


          state.user = { ...user };
          state.resources = _.cloneDeep(resources);


          rdispatch({ type: 'notaries/add', payload: notaries });
          rdispatch({ type: 'roles/fetchRolesSuccess', payload: roles });
          rdispatch({ type: 'permissions/fetchPermissionsSuccess', payload: permissions });
        }

        dispatch({
          type: 'INITIALISE',
          payload: {
            isPendingAuthentication: false,
            isAuthenticated: true,
            refreshToken,
            accessToken,
            user: state.user,
            resources: state.resources,
            token: null,
          },
        });
      } else if (secondFactorCode) {
        // if two factor token is not expired
        if (isValidToken(secondFactorCode)) {
          set2FaSession(secondFactorCode);
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              isPendingAuthentication: true,
              user: null,
              accessToken: null,
              refreshToken: null,
              resources: [],
              token: null,
            },
          });
        } else {
          // otherwise delete the two-factor token from localStorage
          set2FaSession(null);
        }
      } else if(!isIframe) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isPendingAuthentication: false,
            isAuthenticated: false,
            accessToken: null,
            refreshToken: null,
            resources: [],
            user: null,
            token: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALISE',
        payload: {
          isPendingAuthentication: false,
          isAuthenticated: false,
          resources: [],
          accessToken: null,
          refreshToken: null,
          user: null,
          token: null,
        },
      });
    }
  }, [api, rdispatch, set2FaSession, setSession, state]);


  useEffect(() => {
    if (isIframe) {
      const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      const eventer = window[eventMethod];
      const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

      const handleMessage = (e) => {
        const key = e.message ? 'message' : 'data';
        const data = e[key];

        const token = _.get(data, 'token', null);

        if (token) {
          localStorage.setItem('accessToken', token);

          if (!state.isInitialised) {
            initialise();
          }
        }

        const action = _.get(data, 'action', null);

        if (action) {
          if (action === 'logout') {
            window.localStorage.removeItem('accessToken');
            window.localStorage.removeItem('refreshToken');
          }
        }
      };

      // Listen to message from child window
      eventer(messageEvent, handleMessage, false);
      notifyParentWindow('content-initialized');

      return () => {
        const removeEventMethod = window.removeEventListener ? 'removeEventListener' : 'detachEvent';
        const removeEventer = window[removeEventMethod];
        const removeMessageEvent = removeEventMethod === 'detachEvent' ? 'onmessage' : 'message';

        removeEventer(removeMessageEvent, handleMessage);
      };
    }
  }, [initialise, isIframe, notifyParentWindow, refreshAccessToken, state.isInitialised]);


  useEffect(() => {
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, rdispatch, set2FaSession, setSession]);


  useEffect(() => {
    if (state.isInitialised) {
      if (!state.isAuthenticated) {
        if (publicRoutes.indexOf(router.pathname) === -1 && !isIframe) {
          log('redirecing to login page');
          // router.push('/account/login');
          window.location.href = '/account/login';
        }
      } else if (publicRoutes.indexOf(router.pathname) !== -1) {
        log('redirecing from login page');
        router.push('/enterprise/dashboard');
      }
    }
  }, [isIframe, router, router.pathname, state.isAuthenticated, state.isInitialised]);


  useEffect(() => {
    const handleStorageUpdate = (e) => {
      if (e.key !== 'accessToken' && e.key !== 'refreshToken') {
        return;
      }

      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (accessToken !== state.token && refreshToken !== state.refreshToken) {
        if (!accessToken && !refreshToken) {
          logout();
        } else {
          initialise(true);
        }
      }
    };

    window.addEventListener('storage', handleStorageUpdate);

    return () => {
      window.removeEventListener('storage', handleStorageUpdate);
    };
  }, [initialise, logout, state.refreshToken, state.token]);

  if (!state.isInitialised || (!state.isAuthenticated && publicRoutes.indexOf(router.pathname) === -1)) {
    return <LoadingSpinner />;
  }


  return (
    <AuthContext.Provider
      value={{
        ...state,
        getAuth: () => ({
          ...state,
          secondFactorToken: state.token,
        }),
        method: 'JWT',
        login2fa,
        login,
        logout,
        initialise,
        refreshAccessToken,
      }}
    >
      <IdleProvider
        isAuthenticated={state.isAuthenticated}
        accessToken={state.token}
        refreshToken={state.refreshToken}
        onRefreshToken={refreshAccessToken}
        onLogout={() => logout()}
      >

        {children}
      </IdleProvider>
    </AuthContext.Provider>
  );
};

export default AuthContext;


export const useAuth = () => useContext(AuthContext);

// noop
export function useRequireAuth() {
  const authUtils = useAuth();

  return authUtils;
}

export const withAuth = (Component) => (props) => {
  const auth = useAuth();

  return (
    <Component {...props} auth={auth} />
  );
};
