/* eslint-disable brace-style */
/* eslint-disable no-trailing-spaces */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import getConfig from 'next/config';

const { publicRuntimeConfig: conf } = getConfig();


const LogoutWarningModal = ({
  show,
  onStayLoggedIn,
}) => {
  const [secondsRemaining, setSecondsRemaining] = useState(0);

  useEffect(() => {
    // when show is set to true, set seconds remaining
    if (show) {
      setSecondsRemaining(parseInt(conf.SECONDS_BEFORE_LOGOUT_WARNING));
    }
    // reset seconds remaining if show is not set to true
    else {
      setSecondsRemaining(0);
    }
  }, [show]);


  useEffect(() => {
    // exit early when we reach 0
    if (!secondsRemaining) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setSecondsRemaining(secondsRemaining - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect when we update it
  }, [secondsRemaining]);


  const msg = useMemo(() => moment.duration(secondsRemaining, 'seconds').humanize(), [secondsRemaining]);


  return (
    <>
      <Modal
        onHide={onStayLoggedIn}
        show={show}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdropClassName='logoutWarningModalBackdrop'
        style={{ zIndex: 9999 }}
      >
        <Modal.Header>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will be automatically logged out for inactivity in approximately {msg}.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={onStayLoggedIn}
          >
            Stay logged in
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogoutWarningModal;
