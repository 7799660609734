import { Component } from 'react';

import PropTypes from 'prop-types';
import getConfig from 'next/config';
import _ from 'lodash';

import debug from 'debug';
import { BroadcastChannel } from 'broadcast-channel';
import LogoutWarningModal from 'components/Modals/LogoutWarningModal';
import Worker from './workers/idle.worker';
import { withUseIsIframe } from './useIsIframe';


const log = debug('hooks:useIdle');
const plog = log.extend('IdleProvider');

const { publicRuntimeConfig: conf } = getConfig();

const events = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
];


class _IdleProvider extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }


  componentDidMount() {
    plog('cdm', { currentProps: this.props });
    this.channel = new BroadcastChannel('activity');

    if (this.props.isIframe) {
      plog('cdm skipping worker initialization because running in an iframe');

      return;
    }

    if (this.props.isAuthenticated) {
      plog('cdm initializing worker');
      this.initWorker();
    } else {
      plog('cdm', this.worker);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isIframe) {
      return null;
    }

    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      if (this.props.isAuthenticated) {
        plog('user is now authenticated');
        this.initWorker();
      } else {
        plog('user is now unauthenticated');

        return this.clearWorker();
      }
    } else if (this.props.accessToken && this.props.accessToken !== prevProps.accessToken) {
      plog('access token changed. sending initialize to worker');

      this.worker.postMessage({
        action: 'initialize',
        data: {
          // 60 seconds w/o activity means the user is idle
          accessToken: this.props.accessToken,
          refreshToken: this.props.refreshToken,
          idleTime: 10000,
          secondsBeforeWarning: parseInt(conf.SECONDS_BEFORE_LOGOUT_WARNING, 10),
        },
      });
    }
  }

  componentWillUnmount() {
    return this.clearWorker();
  }

  clearWorker = () => {
    if (this.worker) {
      plog('uninitializing worker. removing events');
      this.worker.removeEventListener('message', this.handleMessage);

      _.map(this.eventsToRegister, (evt) => window.removeEventListener(...evt));
      this.worker.terminate();
      this.worker = null;
    }

    this.channel.removeEventListener('message', this.handleChannelMessage);
  }

  handleMessage = (e) => {
    // eslint-disable-next-line no-unused-vars
    const { data: { action, data } } = e;

    switch (action) {
      case 'showWarning':
        // setIdleTime(new Date());
        return this.setState({ showWarning: true });

      case 'hideWarning':
        // setIdleTime(new Date());
        return this.setState({ showWarning: false });

      case 'refreshToken':
        return this.props.onRefreshToken();

      case 'logout':
        this.setState({ showWarning: false });

        return this.props.onLogout();

      default:
        return null;
    }
  }


  handleChannelMessage = (msg) => {
    if (this.props.isIframe) {
      return null;
    }

    if (msg === 'clearWarning') {
      this.setState({ showWarning: false });

      return this.worker.postMessage({
        action: 'initialize',
        data: {
          // 60 seconds w/o activity means the user is idle
          accessToken: localStorage.getItem('accessToken'),
          refreshToken: localStorage.getItem('this.props.refreshToken'),
          idleTime: 10000,
          secondsBeforeWarning: parseInt(conf.SECONDS_BEFORE_LOGOUT_WARNING, 10),
        },
      });
    }

    if (this.worker) {
      this.worker.postMessage({ action: msg });
    }
  }

  initWorker = () => {
    const worker = this.worker = (!this.worker) ? new Worker() : this.worker;

    this.worker.addEventListener('message', this.handleMessage);

    this.channel.addEventListener('message', this.handleChannelMessage);

    const handleWindowEvents = (evt) => () => {
      log(`event triggered - ${evt}`);

      if (!this.worker) {
        return;
      }

      this.channel.postMessage('idleReset');
      worker.postMessage({ action: 'idleReset' });
    };

    this.eventsToRegister = _.map(events, (evt) => [evt, handleWindowEvents(evt)]);

    _.map(this.eventsToRegister, (evt) => window.addEventListener(...[...evt, { passive: true, capture: true }]));

    this.worker.postMessage({
      action: 'initialize',
      data: {
        isIframe: this.props.isIframe,
        // 60 seconds w/o activity means the user is idle
        accessToken: this.props.accessToken,
        refreshToken: this.props.refreshToken,
        idleTime: 10000,
        secondsBeforeWarning: parseInt(conf.SECONDS_BEFORE_LOGOUT_WARNING, 10),
      },
    });
  }


  render() {
    return (
      <>
        <LogoutWarningModal
          show={!!(this.state.showWarning && this.props.isAuthenticated)}
          onStayLoggedIn={() => {
            this.props.onRefreshToken();

            if (this.channel) {
              this.channel.postMessage('clearWarning');
            }
          }}
        />
        {this.props.children}
      </>
    );
  }
}

_IdleProvider.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  accessToken: PropTypes.string,
  refreshToken: PropTypes.string,
  onRefreshToken: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};


export const IdleProvider = withUseIsIframe(_IdleProvider);

export default IdleProvider;
