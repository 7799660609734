import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import _ from 'lodash';
import { AxiosError } from 'axios';
import { useRouter } from 'lib/hooks/useRouterIFrame';

interface IErrorContext {
  show: boolean;
  errors: Array<Error | AxiosError>;
  showError: (err: Error, onClose?: () => any) => void;
  hideError: () => void;
  addError: (err: Error) => void;
}

const ErrorContext = createContext<IErrorContext>({
  show: false,
  errors: [],
  showError: () => null,
  hideError: () => null,
  addError: () => null,
});

export const ErrorModal = () => {
  const { show, errors, hideError } = useErrors();

  return (
    <Modal
      show={show}
      style={{ zIndex: 3000 }}
      variant='primary'
      onHide={() => hideError()}
      className={`f-modal ${show && 'f-modal-open'}`}
    >
      <Modal.Header>Error</Modal.Header>
      <Modal.Body>
        {
          _.map(errors, (err, i) => {
            if (_.get(err, 'isAxiosError')) {
              const message = _.get(err, 'response.data.message', err.message);

              return <div key={i}>{message}</div>;
            }

            return <div key={i}>{err.message}</div>;
          })
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={async () => {
            hideError();
          }}
        >
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export function useErrors(): IErrorContext {
  const ctx = useContext(ErrorContext);

  if (!ctx) {
    throw new Error('useErrors must be called within ApiProvider');
  }

  return ctx;
}


export function ErrorsProvider({ children }) {
  const router = useRouter();
  const [showError, setShowError] = useState<boolean>(false);
  const [errors, setErrors] = useState<Error[]>([]);

  return (
    <ErrorContext.Provider
      value={{
        show: showError,
        errors,
        showError: (error) => {
          const message = _.get(error, 'response.data.message', error.message);

          if (_.toLower(message).includes('request aborted')) {
            return;
          }

          setErrors([
            error,
          ]);
          setShowError(true);
        },
        hideError: () => {
          const errorsForRedirect = _.filter(errors, (err) => _.get(err, 'response.status') >= 401);

          setShowError(false);

          if (errorsForRedirect.length > 0) {
            return router.replace('/account/login');
          }
        },
        addError: () => { },
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
}

ErrorsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
