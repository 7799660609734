import React from 'react';
import Loader from 'react-loader-spinner';


function LoadingSpinner(props) {
  if (props.withBackdrop) {
    return (
      <>
        <div className='fade modal-backdrop show' />
        <div
          className='spinner w-100 h-100 d-flex align-items-center justify-content-center'
          style={{
            top: 0,
            left: 0,
          }}
        >
          <Loader
            className='loading-spinner'
            type='ThreeDots'
            color='#186E81'
            height={100}
            width={100}
          />
        </div>
      </>
    );
  }

  return (
    <div className='spinner'>
      <Loader
        type='ThreeDots'
        color='#186E81'
        height={100}
        width={100}
      />
    </div>
  );
}

export default LoadingSpinner;
